/*
Component that acts as main entry point to this single-page app.

Public API exposed via props:
- NONE
*/

//Imported objects etc
import React, {
  lazy,
  Suspense,
  useEffect,
  useState
} from 'react';
import {
  useRoutes,
  Redirect,
  useMatch
} from 'raviger';
import moment from 'moment';
import {
  displayPreferencesStorage,
  initializeAppStorage
} from './store/store';
import { getSearchParams } from './utils/web';
import {
  PASSWORD_RESET__KEY__TOKEN,
  PASSWORD_RESET__KEY__EMAIL,
  DATA_BALANCE__KEY__TX_AMOUNT,
  DATA_BALANCE__KEY__EXPIRATION,
  DATA_BALANCE__WARN_LIMIT__LOW_DATA,
  DATA_BALANCE__WARN_LIMIT__EXPIRATION,
  MSEC2SEC,
  DATA_BALANCE__KEY__RX_AMOUNT
} from './utils/consts';
import { AUTHENTICATION__REDIRECT_AFTER } from './core/authentication/redirect';
import { initializeApiClient } from './api/base/api_client';
import { initializeGlobalDataService } from './service/live_data_service';
import { initializeAuth } from './core/authentication/global';
import LoadingContentSpinner from './components/general/loaders/loading_spinner';
import ApplicationFrame from './components/application/application_frame';
import {
  withGlobalAuth,
  withGlobalAuthManagement
} from './components/authentication/global';
import { appEvents } from './events/app_events';
import Toast from './components/toast/toast';
import { getUserNotifications } from './api/notifications_api';
import { getDataBalance } from './api/billing_api';
import {
  eUnitsInfoAmount,
  infoAmountToStr,
  timeToStr
} from './utils/units';

const isDataBalanceUnactivated = (txAmount, rxAmount, expirationTime, userCreatedAt) => {
  return txAmount < 1 && rxAmount < 1 && (userCreatedAt.getTime() - expirationTime.getTime()) > -3600 * MSEC2SEC;
};

//initializes application local storage
initializeAppStorage();

// Initialize the API client used by the app
initializeApiClient();
initializeAuth();
initializeGlobalDataService();

// Imported pages
const Home = lazy(() => import('./pages/home/home'));
const Settings = lazy(() => import('./pages/settings/settings'));
const SignIn = lazy(() => import('./pages/sign_in/sign_in'));
const PrivacyPolicy = lazy(() => import('./pages/privacy_policy/privacy_policy'));
const DeleteAccount = lazy(() => import('./pages/information/delete_account'));
const TermsOfService = lazy(() => import('./pages/terms_of_service/terms_of_service'));
const Sessions = lazy(() => import('./pages/sessions/sessions'));
const SessionDetails = lazy(() => import('./pages/sessions/session_details'));
const Favorites = lazy(() => import('./pages/favorites/favorites'));
const Vessels = lazy(() => import('./pages/vessels/vessels'));
const VesselDetails = lazy(() => import('./pages/vessels/vessel_details'));
const Warnings = lazy(() => import('./pages/warnings/warnings'));
const Administration = lazy(() => import('./pages/administration/administration'));
const UserAdministration = lazy(() => import('./pages/administration/user_administration'));
const TrackerAdministration = lazy(() => import('./pages/administration/tracker_administration'));

// Imported routes declaration
const routeConfig = {
  '/terms-of-service': {
    render: () => <TermsOfService />,
    noHeader: true
  },
  '/privacy-policy': {
    render: () => <PrivacyPolicy />,
    noHeader: true
  },
  '/info/delete-account': () => <DeleteAccount />,

  '/settings': () => <Settings />,
  '/favorites': () => <Favorites />,

  '/vessels': () => <Vessels />,
  '/vessels/:vesselId': ({ vesselId }) => <VesselDetails vesselId={vesselId} />,

  '/sessions': () => {
    const searchParams = getSearchParams();
    const vesselId = searchParams.get('vesselId') || displayPreferencesStorage.getSelectedLogbookVessel();
    return <Sessions vesselId={vesselId} />
  },
  '/sessions/:sessionId/details': ({ sessionId }) => <SessionDetails sessionIds={[sessionId]} />,
  '/sessions/merged': () => {
    const sessionIds = getSearchParams().get('sessionIds');
    return <SessionDetails sessionIds={sessionIds.split(',')} />;
  },

  '/sign-in': {
    render: () => {
      const searchParams = getSearchParams();
      const redirectAfter = searchParams.get(AUTHENTICATION__REDIRECT_AFTER) || '/home';

      const resetPasswordToken = searchParams.get(PASSWORD_RESET__KEY__TOKEN) || null;
      const resetPasswordEmail = searchParams.get(PASSWORD_RESET__KEY__EMAIL) || null;
      const resetParams = {
        resetPasswordEmail,
        resetPasswordToken
      };

      return (
        <SignIn
          redirectAfter={redirectAfter}
          resetParams={resetParams}
        />
      );
    },
    noHeader: true
  },
  '/home': () => <Redirect to='/' />,
  '/': () => <Home />,

  '/warnings': () => <Warnings />,

  '/admin': () => <Administration />,
  '/admin/users/:userId': ({ userId }) => <UserAdministration userId={userId} />,
  '/admin/trackers/:trackerId': ({ trackerId }) => <TrackerAdministration imei={trackerId} />
};

// Map the routes object to what the router understands (() => JSX.Element)
const appRoutes = {};
const routePaths = [];
for (const path in routeConfig) {
  routePaths.push(path);
  const r = routeConfig[path];
  if (r instanceof Function)
    appRoutes[path] = r;
  else
    appRoutes[path] = r.render;
}

const AuthApplicationFrame = withGlobalAuthManagement(
  withGlobalAuth(ApplicationFrame)
);

const loader = (
  <LoadingContentSpinner size='xl' />
);

const App = ({ user, reloadUser }) => {
  //initializes router
  const route = useRoutes(appRoutes, { matchTrailingSlash: true });
  const routePath = useMatch(routePaths);
  const {
    noHeader = false
  } = routeConfig[routePath] || {};

  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      try {
        await reloadUser();
      } catch (e) {
        //
      } finally {
        setIsInitializing(false);
      }
    };

    initialize();
  }, [reloadUser]);

  useEffect(() => {
    if (!user)
      return;

    const cb = async () => {
      let notifications = [];
      let dataBalance = null;
      try {
        [notifications, dataBalance] = await Promise.all([
          getUserNotifications(),
          getDataBalance()
        ]);
      } catch (e) {
        //
      }

      if (!!dataBalance) {
        const {
          [DATA_BALANCE__KEY__TX_AMOUNT]: txAmount,
          [DATA_BALANCE__KEY__RX_AMOUNT]: rxAmount,
          [DATA_BALANCE__KEY__EXPIRATION]: expirationDate
        } = dataBalance;

        const expiresIn = expirationDate.getTime() - Date.now();

        const wasNeverActivated = isDataBalanceUnactivated(txAmount, rxAmount, expirationDate, user.createdAt);
        const hasLowData = txAmount < DATA_BALANCE__WARN_LIMIT__LOW_DATA;
        const willExpireSoon = expiresIn < DATA_BALANCE__WARN_LIMIT__EXPIRATION;
        const noDataAvailable = txAmount < 1;
        const dataExpired = expiresIn < 1;

        let title = null;
        let message = null;
        if (wasNeverActivated) {
          title = 'Data plan required';
          message = 'Vessels will be unable to connect unless you have a data plan!';
        } else if (dataExpired) {
          title = 'Data plan expired';
          message = `Your data plan has expired on ${timeToStr(expirationDate)}!`;
        } else if (noDataAvailable) {
          title = 'Data plan depleted';
          message = 'All your data has been used!';
        } else if (willExpireSoon) {
          title = 'Data plan expiring soon';
          message = `Your data plan will expire in ${moment.duration(expiresIn).humanize()}!`;
        } else if (hasLowData) {
          title = 'Low data';
          message = `You have ${infoAmountToStr(txAmount, eUnitsInfoAmount.EU_Units)} remaining in your data plan!`;
        }

        if (!!title && !!message) {
          notifications.unshift({
            title,
            message
          });
        }
      }

      for (const { message, title } of notifications) {
        appEvents.display_toast.dispatch({
          title,
          color: 'warning',
          iconType: 'alert',
          toastLifeTimeMs: 30000,
          text: (
            <p>
              {message}
            </p>
          )
        });
      }
    }

    cb();
  }, [user]);

  if (isInitializing)
    return loader;

  return (
    <AuthApplicationFrame noHeader={noHeader}>
      <Toast />

      {!isInitializing && (
        <Suspense fallback={loader}>
          {route}
        </Suspense>
      )}
    </AuthApplicationFrame>
  );
};

export default withGlobalAuthManagement(
  withGlobalAuth(App)
);
